import { API_BASE_URL } from "constants/app.consts";

const BASE_URL_PROD = "https://app.supercreator.ai/";
/**
 *
 * @returns The base API url for the project
 */
export function baseAPIURL() {
  if (isProduction()) {
    return "https://us-central1-supercreator-ai.cloudfunctions.net";
  } else {
    return "https://us-central1-supercreator-ai-staging.cloudfunctions.net";
  }
}
/**
 * Cleans object of empty fields (null, undefined, nil)
 * @param {*} obj The object to clean
 * @returns
 */
export function cleanObject(obj) {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return obj;
}

/**
 * Base URL which should also be used for sharing with others
 */
export function baseShareURL() {
  if (isProduction()) {
    return BASE_URL_PROD;
  } else {
    return "https://supercreator-ai-staging.web.app/";
  }
}
/**
 *
 * @returns base web app url (website hosting) for project
 */
export function baseAppURL() {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return "http://localhost:3000/";
  } else if (isProduction()) {
    return BASE_URL_PROD;
  } else {
    return "https://supercreator-ai-staging.web.app/";
  }
}
/**
 *
 * @returns Base app identifier for ios app
 */
export function baseAppIdentifieriOS() {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return "com.clbcreative.supercreator-ai-staging";
  } else if (isProduction()) {
    return "com.clbcreative.supercreator-ai";
  } else {
    return "com.clbcreative.supercreator-ai-staging";
  }
}
/**
 *
 * @returns Base app identifier for android app
 */
export function baseAppIdentifierAndroid() {
  if (isProduction()) {
    //production
    return "com.clbcreative.supercreator-ai";
  } else if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    //staging
    return "com.clbcreative.supercreator-ai-staging";
  } else {
    //fallback - staging
    return "com.clbcreative.supercreator-ai-staging";
  }
}
/**
 *
 * @returns The base deep linking page link for current project
 */
export function baseAppDeepLinking(allowLocalhost = true) {
  if (isProduction()) {
    //production
    return BASE_URL_PROD;
  } else if (
    (window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1") &&
    allowLocalhost
  ) {
    //local
    return "localhost:3000/";
  } else {
    //fallback - staging
    return "https://supercreator-ai-staging.web.app/";
  }
}

/**
 *
 * @returns The base deep linking page link for current project
 */
export function baseAuthDeepLinking() {
  if (isProduction()) {
    //production
    return "supercreatorai.page.link";
  } else if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    //local
    return "supercreatoraistaging.page.link";
  } else {
    //fallback - staging
    return "supercreatoraistaging.page.link";
  }
}

/**
 * Checks if the current env is production
 * @returns True if the current project is production enviornment
 */
export function isProduction() {
  let env = process.env.REACT_APP_ENV;
  //console.log("proccess.env = ", env);
  return env === "production";
}

/**
 * Checks if the current env is staging
 * @returns True if the current project is staging environment
 */
export function isStaging() {
  let env = process.env.REACT_APP_ENV;
  return env === "staging" || (!isProduction() && !isLocalHost());
}

/**
 * Checks if the current env is production
 * @returns True if the current project is production enviornment
 */
export function isLocalHost() {
  return window.location.hostname.includes("localhost");
}

export const ROUTES = {
  FINISH_CONNECT: "confirm-email",
};

export function capitalizeFirstLetter(string) {
  if (!string) return null;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isCurrentUserAdmin() {
  //get store
  const is_admin = false;
  return is_admin;
}

export const objToJSON = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Converts a base 64 data uri to a blob (image)
 * @param {*} dataURI
 * @returns Blob
 **/
export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);
  // separate out the mime component
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

export const DEFAULT_PROFILE_PICTURE_URL =
  "https://firebasestorage.googleapis.com/v0/b/celebrate-io.appspot.com/o/avatars%2Favatar-1.png?alt=media&token=ab7ecc70-2f7c-4d5b-9e02-721f25378149";

export const getProxiedUrl = (url) => {
  if (!url) return url;
  if (url.includes("use_sc_proxy=true")) {
    const originalUrl = encodeURIComponent(
      url.replace("use_sc_proxy=true", "").trim()
    );
    return `${API_BASE_URL}superpowers?action=get_proxy_image&url=${originalUrl}`;
  }
  return url;
};

export const getFullName = (user) => {
  const first_name = user?.first_name ?? "";
  const last_name = user?.last_name ?? "";
  const spacing = first_name && last_name ? " " : "";
  return `${first_name}${spacing}${last_name}`.trim();
};
