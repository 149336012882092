import { isLocalHost, isProduction } from "util/SCUtils";

export const API_BASE_URL_DEBUG =
  "https://us-central1-supercreator-ai-staging.cloudfunctions.net/";

export const API_BASE_URL_PRODUCTION =
  "https://us-central1-supercreator-ai.cloudfunctions.net/";

export const API_BASE_LOCAL =
  "http://localhost:5001/supercreator-ai-staging/us-central1/";

export const PRIVACY_POLICY_URL =
  "https://docs.google.com/document/d/12gwdPYKYAGmmLRvoeoSceS1vEpzmHbvdEP4vmFvcEHk/view";

export const TERMS_OF_SERVICE =
  "https://docs.google.com/document/d/16Z3VBg_0-OrxZSly47zl90pXvszkNY-BDF0oO96xHeg/view";

export const ASK_COMMUNITY_URL = "https://supercreator.ai/join-discord";

export const HELP_CENTER_URL = "https://supercreator.ai/web-app-guide";

export const SUPERPOWERS_EXTENSION_URL = "superpowers";

const SHARE_BASE_URL_DEBUG = "localhost:3000/";

const SHARE_BASE_URL_PRODUCTION = "app.supercreator.ai/";

export const API_BASE_URL = isProduction()
  ? API_BASE_URL_PRODUCTION
  : isLocalHost()
  ? API_BASE_URL_DEBUG
  : API_BASE_URL_DEBUG;

//Uncomment if we want to direct to local host backend
// export const API_BASE_URL = isProduction()
//   ? API_BASE_URL_PRODUCTION
//   : isLocalHost()
//   ? API_BASE_LOCAL
//   : API_BASE_URL_DEBUG;

export const API_SHARE_URL = isProduction()
  ? SHARE_BASE_URL_PRODUCTION
  : SHARE_BASE_URL_DEBUG;

export let UPLOAD_MB_LIMIT = 50;

export const STRIPE_CUSTOMER_PORTAL_URL = isProduction()
  ? "https://billing.stripe.com/p/login/5kA9Cl9Xue375jO6oo"
  : "https://billing.stripe.com/p/login/test_4gwbJT9G1eeu6AMaEE";
